@import '../../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  margin-inline-start: 24px;

  padding-block-start: 11px;
  padding-inline-end: 0;
  padding-block-end: 17px;
  padding-inline-start: 0;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    margin-inline-start: 0;
  }
}

.fontInput {
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  /* Layout */

  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 24px;
  margin-inline-start: 0;

  padding-block-start: 4px;
  padding-inline-end: 13px;
  padding-block-end: 10px;
  padding-inline-start: 13px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    margin: 0;

    padding-block-start: 5px;
    padding-inline-end: 13px;
    padding-block-end: 15px;
    padding-inline-start: 13px;
  }
}

.mobileInput {
  /* Font */
  composes: fontInput;

  /* Layout */
  height: 50px;

  /* Borders */
  border: 0;
  border-radius: 0;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-block-start: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
}

.desktopInput {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-block-start: 0;
  margin-block-end: 0;

  &:hover,
  &:focus {
    border-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 50px;
  left: 0;
  start: 0;
  /* searchContainer margin-block-start (94px) + mobileInput height (50px) = 144px */
  min-height: calc(100vh - 144px);
  width: 100%;
  background-color: var(--marketplaceColor);
  border-block-end-start-radius: var(--borderRadius);
  border-block-end-end-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    min-height: unset;
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-block-end: 100px;

  @media (--viewportMedium) {
    margin-block-end: 0;
  }
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: var(--topbarHeightDesktop);
  left: 0;
  start: 0;
  background-color: var(--marketplaceColor);
  border-block-end-start-radius: var(--borderRadius);
  border-block-end-end-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-block-start: 0;
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-block-end: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}
